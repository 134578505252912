import http from "./httpService";

class Auth {
  endpoint = "/users";
  tokenKey = "token";
  authUser = "auth_user";
  opsAreaKey = "ops_area"

  login = (email, password) => {
    return http.post(this.endpoint, {
      email,
      password,
    });
  };

  logout = () => {
    localStorage.removeItem(this.authUser);
    localStorage.removeItem(this.tokenKey);
    http.removeAuthToken();

    var loc = window.location.href;
    const current = loc.replace("#", "").replace("&", ";");
    const redirectTo = `/login?redirect=${current}`;

    window.location = redirectTo;
    window.location.href = redirectTo;
  };

  setUser = (user) => {
    const {
      token,
      opsArea
    } = user;
    localStorage.setItem(this.authUser, JSON.stringify(user));
    localStorage.setItem(this.tokenKey, token);
    localStorage.setItem(this.opsAreaKey, JSON.stringify(opsArea));

    this.setDefaultHttpHeaders(user);

  };

  setOpsArea(opsArea) {
    if (opsArea) {
      localStorage.removeItem(this.opsAreaKey);
      localStorage.setItem(this.opsAreaKey, JSON.stringify(opsArea));
      http.setOpsArea(opsArea.id);
    }

  }

  setAuthToken(token) {
    if (token) http.setAuthToken(token);
  }

  setDefaultHttpHeaders(user) {
    const {
      token
    } = user;
    if (token) http.setAuthToken(token);

    const opsArea = this.getSelectedOpsArea();

    console.log("opsArea", opsArea);

    if (opsArea && opsArea.id) {
      http.setOpsArea(opsArea.id);
    }

  }

  getSelectedOpsArea = () => {
    try {
      return JSON.parse(localStorage.getItem(this.opsAreaKey));
    } catch (ex) {
      return null;
    }
  };

  getCurrentUser = () => {
    try {
      return JSON.parse(localStorage.getItem(this.authUser));
    } catch (ex) {
      return null;
    }
  };
}

export default new Auth();