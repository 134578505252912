import DJHLoadable from "../../Loadable/DJHLoadable";

const OrderReport = DJHLoadable({
    loader: () => import('./orders/ordersReport')
})

const DriverReport = DJHLoadable({
    loader: () => import('./drivers/driverReport')
})

const OrdersBranchReport = DJHLoadable({
    loader: () => import('./branch-breakdown/ordersBranchReport')
})

const KpiReport = DJHLoadable({
    loader: () => import('./kpi/kpiReport')
})

const VendorOrderReport = DJHLoadable({
    loader: () => import('./vendorReport/vendorOrderReport')
})


const reportsRoute = [{
        path: `${process.env.PUBLIC_URL}/reports/kpi`,
        exact: true,
        component: KpiReport
    },
    {
        path: `${process.env.PUBLIC_URL}/reports/vendor`,
        exact: true,
        component: VendorOrderReport
    },
    {
        path: `${process.env.PUBLIC_URL}/reports/orders`,
        exact: true,
        component: OrderReport
    },
    {
        path: `${process.env.PUBLIC_URL}/reports/orders/:id`,
        exact: true,
        component: OrderReport
    },
    {
        path: `${process.env.PUBLIC_URL}/reports/driver/workinghours/:id`,
        exact: true,
        component: DriverReport
    },
    {
        path: `${process.env.PUBLIC_URL}/reports/ordersBranch/:id`,
        exact: true,
        component: OrdersBranchReport
    }
]

export default reportsRoute;