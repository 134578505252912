import React, { Component } from "react";
import { connect } from "react-redux";
class UserPanel extends Component {
  render() {
    const { user } = this.props;
    return (
      <div className="sidebar-user text-center">
        <div>
          {/* <img
            className="img-50 rounded-circle"
            src={require("./../../../assets/images/user/1.jpg")}
            alt="#"
          /> */}
        </div>
        <h6 className="mt-3 f-12">Hey, {user.displayName}</h6>
        {/* <a href="#">
          <i className="fa fa-circle text-success"></i> Online
        </a> */}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});
export default connect(mapStateToProps, null)(UserPanel);
