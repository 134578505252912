import axios from "axios";
import {
  toast
} from "react-toastify";
import auth from './authService'

var api = window.location.origin;

// axios.defaults.baseURL = `https://${(api.includes("localhost") ? 'localhost:44333' : 'portalapi.foodiyoo.com')}/api`;

axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}/api`;

axios.interceptors.response.use(null, error => {
  if (error.response) {
    const {
      status
    } = error.response;
    const expectedError = error.response && status >= 400 && status < 500;

    if (status === 401) {
      //Logout
      auth.logout()
      // alert("Error 401");
    }
    if (!expectedError) {
      console.log("Logging the error", error);
    } else {
      toast.error(error.response.data.message);
    }
  } else {
    toast.error("Unexpected error, try again later");
  }

  return Promise.reject(error);
});

function setAuthToken(token) {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
}

function setOpsArea(areaId) {
  if (areaId)
    axios.defaults.headers.common["X-parent-area-id"] = `${areaId}`;
}

function removeAuthToken() {
  delete axios.defaults.headers.common["Authorization"];
}

function uploadFile(endpoint, files) {
  if (files.length === 0) return;

  const formData = new FormData();

  for (let file of files) formData.append(file.name, file);

  // formData.append('avatar',file)

  return axios.post(endpoint, formData, {
    headers: {
      "content-type": "multipart/form-data"
    }
  });
}
export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setAuthToken,
  removeAuthToken,
  uploadFile,
  setOpsArea
};