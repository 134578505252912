import DJHLoadable from "../../Loadable/DJHLoadable";

const MainVendorTable = DJHLoadable({
  loader: () => import("./MainVendors")
});

const vendorBranches = DJHLoadable({
  loader: () => import("./vendorBranches")
});

const vendorRoute = [{
    path: `${process.env.PUBLIC_URL}/vendors`,
    exact: true,
    component: MainVendorTable
  },
  {
    path: `${process.env.PUBLIC_URL}/vendors/:id`,
    component: vendorBranches,
    exact: true
  }
];

export default vendorRoute;