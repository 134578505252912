import {
    SET_USER_DATA,
    REMOVE_USER_DATA,
    USER_LOGGED_OUT
} from "../actionTyps";

import auth from "../../services/authService";

export const setUserData = (user) => dispatch => {
    dispatch({
        type: SET_USER_DATA,
        data: user
    })

}

export const logoutUser = () => dispatch => {
    return new Promise((resolve, reject) => {
        auth.logout()
        dispatch({
            type: USER_LOGGED_OUT
        })
        resolve()
    })

}