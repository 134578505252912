import React from "react";

const Checkbox = ({ name, lable, checked, onChange, ...rest }) => {
  return (
    <div className="checkbox form-check">
      <input
        type="checkbox"
        name={name}
        id={name}
        {...rest}
        className="form-check-input"
        checked={checked}
        onChange={e => onChange(e.target)}
      />
      <label className="form-check-label" htmlFor={name}>
        {lable}
      </label>
    </div>
  );
};

export default Checkbox;
