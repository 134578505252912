import React, { Component } from "react";
import Joi from "joi-browser";
import Input from "./input";
import Select from "./select";
import Checkbox from "./checkbox";
import DJHBaseComponent from "./DJHBaseComponent";
class Form extends DJHBaseComponent {
  //  inputTypes = {
  //   input: 1,
  //   checkbox: 2,
  //   select: 3,
  //   radioButton: 4,
  // };

  state = {
    data: {},
    errors: {},
    fieldList: [],
  };

  validate = () => {
    if (!this.schema) return null;
    const options = { abortEarly: false };
    const { error } = Joi.validate(this.state.data, this.schema, options);
    if (!error) return null;

    if (window.location.origin.includes("localhost")) console.log(error);

    const errors = {};

    for (let item of error.details) errors[item.path[0]] = item.message;

    return Object.keys(errors).length === 0 ? null : errors;
  };

  vlidateProperty = ({ name, value }) => {
    if (!this.schema) return null;
    if (!this.schema[name]) return null;
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;

    this.doSubmit();
  };

  handleChange = ({ currentTarget: input }) => {
    //Validation
    const errors = { ...this.state.errors };
    const errorMessage = this.vlidateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data, errors });
  };

  handleCheckboxChange = ({ name, checked }) => {
    const data = { ...this.state.data };
    data[name] = checked;
    this.setState({ data });
  };

  renderButton = (label, disabled = false) => {
    let btnDisabled = this.validate();
    if (disabled === true) btnDisabled = true;

    return (
      <button disabled={btnDisabled} className="btn btn-primary">
        {label}
      </button>
    );
  };

  renderInput = (name, label, type = "text") => {
    const { data, errors } = this.state;
    return (
      <Input
        type={type}
        error={errors[name]}
        value={data[name]}
        onChange={this.handleChange}
        name={name}
        lable={label}
      />
    );
  };

  renderSelect = (name, label, options) => {
    const { data, errors } = this.state;
    return (
      <Select
        options={options}
        error={errors[name]}
        value={data[name]}
        onChange={this.handleChange}
        name={name}
        lable={label}
      />
    );
  };

  renderCheckbox = (name, label) => {
    const { data } = this.state;
    return (
      <Checkbox
        checked={data[name]}
        onChange={this.handleCheckboxChange}
        name={name}
        lable={label}
      />
    );
  };

  handleChangeDynamicly = (index, { currentTarget: input }) => {
    //Validation
    const { fieldList } = this.state;
    const errorMessage = null;
    if (errorMessage) fieldList[index].error = errorMessage;
    else fieldList[index].error = null;

    fieldList[index].value = input.value;
    this.setState({ fieldList });
    return;
  };

  renderInputDynamicly = (index) => {
    const { data, fieldList } = this.state;
    const item = fieldList[index];
    const id = item.id ? item.id : item.name;
    const type = item.type ? item.type : "text";
    const { name, label, value, error } = item.name;

    return (
      <Input
        type={type}
        error={error}
        value={value}
        onChange={(e) => this.handleChangeDynamicly(index, e)}
        id={id}
        name={name}
        lable={label}
      />
    );
  };
}

export default Form;
