import {
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  LOGIN_LOADING,
  REST_PASSWORD
} from "../actionTyps";

const initState = {
  success: false,
  loading: false,
  error: {
    message: ""
  }
};

const LoginReducer = (state = initState, action) => {
  switch (action.type) {
    case LOGIN_LOADING: {
      return {
        ...state,
        loading: true
      };
    }

    case LOGIN_SUCCESS: {
      return {
        ...state,
        success: true,
        loading: false
      };
    }

    case LOGIN_ERROR: {
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }

    default: {
      return state;
    }
  }
};

export default LoginReducer;
