import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import auth from "../../services/authService";
import { setUserData } from "../../redux/actions/userActions";
class Auth extends Component {
  state = {};

  componentDidMount() {
    this.checkAuth();
  }

  checkAuth = () => {
    //Validate server login
    const user = auth.getCurrentUser();
    if (user && user.token) {
      this.props.setUserData(user);
      auth.setDefaultHttpHeaders(user);

      // auth.setAuthToken(user.token);
      // auth.setOpsArea(user.opsArea);
    }
  };

  render() {
    const { children } = this.props;
    return <Fragment>{children}</Fragment>;
  }
}

const mapStateToProps = (state) => ({
  setUserData: PropTypes.func.isRequired,
  login: state.login,
});

export default connect(
  mapStateToProps,
  { setUserData }
)(Auth);
