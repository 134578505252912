import vendorRoute from "./portal/vendors/routes";
import coverageAreaRoute from "./portal/coverageArea/routes";
import vendorMenuRoute from "./portal/vendorMenu/routes";
import cuisinesRoute from "./portal/cuisines/routes";
import driversRoute from "./portal/drivers/routes";
import deliveryAreaRoute from "./portal/deliveryAreas/routes";
import ordersRoute from "./portal/orders/routes";
import voucherRoute from "./portal/vouchers/routes";
import reportsRoute from "./portal/reports/routes";
import bannersRoute from "./portal/banners/routes";
const routes = [
  ...vendorMenuRoute,
  ...coverageAreaRoute,
  ...vendorRoute,
  ...cuisinesRoute,
  ...driversRoute,
  ...deliveryAreaRoute,
  ...ordersRoute,
  ...voucherRoute,
  ...reportsRoute,
  ...bannersRoute,
];

export default routes;
