export const MENUITEMS = [{
        title: 'Hub',
        icon: 'icon-desktop',
        type: 'link',
        active: false,
        path: '/dashboard/default'

    }, {
        title: 'Vendors',
        icon: 'icon-harddrives',
        type: 'sub',
        active: false,
        children: [{
            path: '/vendors',
            title: 'All',
            type: 'link'
        }]
    },
    {
        title: 'Cuisines',
        icon: 'fa fa-cutlery',
        type: 'link',
        active: false,
        path: '/cuisines'
    },
    {
        title: 'Delivery Areas',
        icon: 'fa fa-map-marker',
        type: 'link',
        active: false,
        path: '/deliveryArea'
    },
    {
        title: 'Orders',
        icon: 'fa fa-smile-o',
        type: 'sub',
        active: false,
        children: [{
                path: '/orders?status=2',
                icon: 'fa fa-money',
                title: 'Confirmed',
                type: 'link'
            },
            {
                path: '/orders?status=0',
                icon: 'fa fa-frown-o',
                title: 'Unconfirmed',
                type: 'link'
            }, {
                path: '/orders?status=3',
                icon: 'fa fa-ban',
                title: 'Canceled',
                type: 'link'
            }
        ]
    },
    {
        title: 'Drivers',
        icon: 'fa fa-truck',
        type: 'link',
        active: false,
        path: '/drivers'
    },
    {
        title: 'Vouchers',
        icon: 'fa fa-money',
        type: 'link',
        active: false,
        path: '/vouchers'
    },
    {
        title: 'Reports',
        icon: 'fa fa-bar-chart',
        type: 'sub',
        active: false,
        children: [{
                path: '/reports/kpi',
                icon: 'fa fa-bar-chart',
                title: 'KPI',
                type: 'link'
            },
            {
                path: '/reports/vendor',
                icon: 'fa fa-bar-chart',
                title: 'Vendor Orders',
                type: 'link'
            },
            {
                path: '/reports/orders',
                icon: 'fa fa-money',
                title: 'Orders',
                type: 'link'
            },
            {
                path: '/reports/ordersBranch/0',
                icon: 'fa fa-money',
                title: 'Branches Orders',
                type: 'link'
            }
        ]
    }, {
        title: 'Banners',
        icon: 'fa fa-picture-o',
        type: 'link',
        active: false,
        path: '/banners'
    },

]