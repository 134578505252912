import React, { Component } from "react";

//coulumns
// onSort
// SortCoulmn
class TableHeader extends Component {
  raiseSort = path => {
    const sortColumn = { ...this.props.sortColumn };
    let order = "asc";
    if (sortColumn.path === path && sortColumn.order === order) order = "desc";

    sortColumn.path = path;
    sortColumn.order = order;
    this.props.onSort(sortColumn);
  };

  renderSortIcon = coulumn => {
    const { sortColumn } = this.props;
    if (coulumn.path !== sortColumn.path) return null;

    if (sortColumn.order === "asc") return <i className="fa fa-sort-asc"></i>;

    return <i className="fa fa-sort-desc"></i>;
  };

  render() {
    const { coulumns } = this.props;
    return (
      <thead>
        <tr>
          {coulumns.map(coulumn => (
            <th
              className="clickable"
              key={coulumn.path || coulumn.key}
              onClick={() => this.raiseSort(coulumn.path)}
            >
              {coulumn.label} {this.renderSortIcon(coulumn)}
            </th>
          ))}
        </tr>
      </thead>
    );
  }
}

export default TableHeader;
