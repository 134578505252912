import React from "react";
import _ from "lodash";
import PropTyps from "prop-types";
import Pagination from "react-js-pagination";

const DJHPagination = (props) => {
  const { itemsCount, pageSize, onPageChange, currentPage } = props;
  const pagesCount = Math.ceil(itemsCount / pageSize);
  const pages = _.range(1, pagesCount + 1);

  if (pagesCount === 1) return null;

  return (
    <Pagination
      innerClass="pagination justify-content-center my-4"
      itemClass="page-item"
      linkClass="page-link"
      activeLinkClass="btn-primary"
      activePage={currentPage}
      itemsCountPerPage={pageSize}
      totalItemsCount={itemsCount}
      pageRangeDisplayed={10}
      onChange={(page) => onPageChange(page)}
    />

    // <nav aria-label="Page navigation">
    //   <ul className="pagination react-bootstrap-table-page-btns-ul m-2 pt-4">
    //     {pages.map(page => (
    //       <li
    //         className={currentPage === page ? "page-item active" : "page-item"}
    //         key={page}
    //       >
    //         <a
    //           className={
    //             currentPage === page ? "page-link btn-primary" : "page-link"
    //           }
    //           onClick={() => onPageChange(page)}
    //         >
    //           {page}
    //         </a>
    //       </li>
    //     ))}
    //   </ul>
    // </nav>
  );
};

DJHPagination.propTypes = {
  itemsCount: PropTyps.number.isRequired,
  pageSize: PropTyps.number.isRequired,
  onPageChange: PropTyps.func.isRequired,
  currentPage: PropTyps.number.isRequired,
};
export default DJHPagination;
