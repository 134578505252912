import React from "react";
import TableHeader from "./tableHeader";
import TableBody from "./tableBody";
import { usePromiseTracker } from "react-promise-tracker";
import { Spinner } from "./components/spinner/spinner";

const Table = ({
  coulumns,
  onSort,
  sortColumn,
  items,
  spinnerArea,
  tFooter,
}) => {
  //   const { coulumns, onSort, sortColumn, items } = props;

  return (
    <React.Fragment>
      <Spinner area={spinnerArea} />
      <table className="table table-bordered text-center table-striped">
        <TableHeader
          coulumns={coulumns}
          onSort={onSort}
          sortColumn={sortColumn}
        />
        <TableBody coulumns={coulumns} data={items} />
        {tFooter}
      </table>
    </React.Fragment>
  );
};

export default Table;
