import {
    combineReducers
} from 'redux';
import LoginReducer from './loginReducer';
import UserReducer from './userReducer';


const RootReducer = combineReducers({
    login: LoginReducer,
    user: UserReducer
})

export default RootReducer;