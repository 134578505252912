import React, { Component } from "react";
import { SideNav, Nav } from "react-sidenav";
import { Link } from "react-router-dom";
import MetisMenu from "react-metismenu";
import RouterLink from "react-metismenu-router-link";
import { MENUITEMS } from "../../menu";

// Import custom components
import UserPanel from "./user-panel.component";

class Sidebar extends Component {
  state = { selectedPath: "1", mainmenu: [] };
  onItemSelection = (arg, e) => {
    this.setState({ selectedPath: arg.path });
  };

  componentWillMount() {
    this.setState({
      mainmenu: MENUITEMS,
    });
  }
  componentDidMount() {
    var currentUrl = window.location.pathname;

    this.state.mainmenu.filter((items) => {
      if (!items.children) {
        if (items.path === currentUrl) this.setNavActive(items);
        return false;
      }
      items.children.filter((subItems) => {
        if (subItems.path === currentUrl) this.setNavActive(subItems);
        if (!subItems.children) return false;
        subItems.children.filter((subSubItems) => {
          if (subSubItems.path === currentUrl) this.setNavActive(subSubItems);
        });
      });
    });
  }

  setNavActive(item) {
    MENUITEMS.filter((menuItem) => {
      if (menuItem != item) menuItem.active = false;
      if (menuItem.children && menuItem.children.includes(item))
        menuItem.active = true;
      if (menuItem.children) {
        menuItem.children.filter((submenuItems) => {
          if (submenuItems.children && submenuItems.children.includes(item))
            menuItem.active = true;
          if (submenuItems != item) submenuItems.active = false;
        });
      }
    });
    item.active = !item.active;

    this.setState({
      mainmenu: MENUITEMS,
    });
  }

  render() {
    const theme = {
      selectionColor: "#C51162",
    };

    const mainmenu = this.state.mainmenu.map((menuItem, i) => (
      <li className={`${menuItem.active ? "active" : ""}`} key={i}>
        {menuItem.sidebartitle ? (
          <div className="sidebar-title">{menuItem.sidebartitle}</div>
        ) : (
          ""
        )}

        {menuItem.type === "sub" ? (
          <a
            className="sidebar-header "
            onClick={() => this.setNavActive(menuItem)}
          >
            <i className={`${menuItem.icon}`}></i>

            <span>{menuItem.title}</span>
            <i className="fa fa-angle-right pull-right"></i>
          </a>
        ) : (
          ""
        )}
        {menuItem.type === "link" ? (
          <Link
            to={`${process.env.PUBLIC_URL}${menuItem.path}`}
            className={`sidebar-header ${menuItem.active ? "Active" : ""}`}
            onClick={() => this.setNavActive(menuItem)}
          >
            <i className={`${menuItem.icon}`}></i>
            <span>{menuItem.title}</span>
            {menuItem.children ? (
              <i className="fa fa-angle-right pull-right"></i>
            ) : (
              ""
            )}
          </Link>
        ) : (
          ""
        )}

        {menuItem.children ? (
          <ul
            className={`sidebar-submenu ${menuItem.active ? "menu-open" : ""}`}
            style={
              menuItem.active
                ? { opacity: 1, transition: "opacity 500ms ease-in" }
                : {}
            }
          >
            {menuItem.children.map((childrenItem, index) => (
              <li
                key={index}
                className={
                  childrenItem.children
                    ? childrenItem.active
                      ? "active"
                      : ""
                    : ""
                }
              >
                {childrenItem.type === "sub" ? (
                  <a onClick={() => this.setNavActive(childrenItem)}>
                    <i className="fa fa-angle-right"></i>
                    {childrenItem.title}
                  </a>
                ) : (
                  ""
                )}

                {childrenItem.type === "link" ? (
                  <Link
                    to={`${process.env.PUBLIC_URL}${childrenItem.path}`}
                    className={childrenItem.active ? "active" : ""}
                    onClick={() => this.setNavActive(childrenItem)}
                  >
                    {childrenItem.icon ? (
                      <i className={`${childrenItem.icon}`}></i>
                    ) : (
                      <i className="fa fa-angle-right"></i>
                    )}
                    {childrenItem.title}{" "}
                  </Link>
                ) : (
                  ""
                )}

                {childrenItem.children ? (
                  <ul
                    className={`sidebar-submenu ${
                      childrenItem.active ? "menu-open" : ""
                    }`}
                  >
                    {childrenItem.children.map((childrenSubItem, key) => (
                      <li
                        className={childrenSubItem.active ? "active" : ""}
                        key={key}
                      >
                        {childrenSubItem.type === "link" ? (
                          <Link
                            to={`${process.env.PUBLIC_URL}${childrenSubItem.path}`}
                            className={childrenSubItem.active ? "active" : ""}
                          >
                            <i className="fa fa-angle-right"></i>
                            {childrenSubItem.title}
                          </Link>
                        ) : (
                          ""
                        )}
                      </li>
                    ))}
                  </ul>
                ) : (
                  ""
                )}
              </li>
            ))}
          </ul>
        ) : (
          ""
        )}
      </li>
    ));

    return (
      <div className="page-sidebar custom-scrollbar page-sidebar-open">
        <UserPanel />
        <ul className="sidebar-menu">{mainmenu}</ul>

        {/* <div className="sidebar-widget text-center">
          <div className="sidebar-widget-top">
            <h6 className="mb-2 fs-14">Need Help</h6>
            <i className="icon-bell"></i>
          </div>
          <div className="sidebar-widget-bottom p-20 m-20">
            <p>
              +1 234 567 899 help@pixelstrap.com <a href="#">Visit FAQ</a>
            </p>
          </div>
        </div>
       */}
      </div>
    );
  }
}

export default Sidebar;
