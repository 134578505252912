import React, { Component } from "react";

// import { useLocation } from "react-router-dom";
class DJHBaseComponent extends Component {
  state = { startDate: null, endDate: null, focusedInput: null };
  useQuery = () => {
    return new URLSearchParams(this.props.history.location.search);
  };

  getQuery = (key) => {
    let query = this.useQuery();
    return query.get(key);
  };

  setQuery = (key, value) => {
    const { history } = this.props;
    const query = new URLSearchParams(history.location.search);
    query.set(key, value);
    this.props.history.replace({
      ...history.location,
      search: query.toString(),
    });

    // let query = this.useQuery();
    // query[key] = value;
    // query.forEach((item) => console.log("item:", item));
  };

  setTitle = (title) => {
    document.title = `${title} | Foodiyoo Dashboard`;
  };
}

export default DJHBaseComponent;
