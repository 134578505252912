import DJHLoadable from "../../Loadable/DJHLoadable";
const coverageArea = DJHLoadable({
    loader: () => import("./coverageArea")
});

const coverageAreaRoute = [{
    path: `/vendors/branch/coverage/:id`,
    exact: true,
    component: coverageArea
}];

export default coverageAreaRoute;