import React, { Component } from "react";
import auth from "../../../services/authService";
import Modal from "react-responsive-modal";
import DeliveryAreaList from "./deliveryAreaList";

class OpsAreaSelection extends Component {
  state = {
    modalEdit: { open: false },
  };

  handleSuccess = (item) => {
    auth.setOpsArea({ id: item.id, name: item.name });
    this.handleCloseEditModal();
    window.location.href = window.location.href;
  };
  handleCloseEditModal = () => {
    this.setState({ modalEdit: { open: false } });
  };

  handleEditModal = () => {
    this.setState({
      modalEdit: { open: true },
    });
  };

  render() {
    const opsArea = auth.getSelectedOpsArea();
    const { modalEdit } = this.state;
    return (
      <>
        <li className="">
          <button className="btn" onClick={this.handleEditModal}>
            {opsArea && opsArea.name}
          </button>
        </li>

        {modalEdit.open && (
          <Modal open={modalEdit.open} onClose={this.handleCloseEditModal}>
            <DeliveryAreaList
              // onClickClose={this.handleCloseEditModal}
              onSuccess={this.handleSuccess}
              selectedItem={opsArea}
            />
          </Modal>
        )}
      </>
    );
  }
}

export default OpsAreaSelection;
