import DJHLoadable from "../../Loadable/DJHLoadable";

const DriversList = DJHLoadable({
    loader: () => import('./driversList')
})
const driverRoute = [{
    path: `${process.env.PUBLIC_URL}/drivers`,
    exact: true,
    component: DriversList
}]

export default driverRoute;