import React, { Component, Fragment } from "react";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import auth from "../../services/authService";
import { PropTypes } from "prop-types";
import { setUserData } from "../../redux/actions/userActions";
class AuthGuard extends Component {
  constructor(props, context) {
    super(props);
    let { routes } = context;

    let authenticated = false;
    const user = auth.getCurrentUser();
    if (user) {
      if (user.token) {
        this.props.setUserData(user);
        auth.setDefaultHttpHeaders(user);
        authenticated = true;
      }
    }
    this.state = {
      authenticated,
      routes,
    };
  }

  // componentDidMount() {
  // }

  checkAuth = () => {
    //Validate server login
    const user = auth.getCurrentUser();
    if (user.token) {
      this.props.setUserData(user);
      auth.setDefaultHttpHeaders(user);
      this.setState({ authenticated: true });
    } else {
      this.redirectRoute(this.props);
    }
  };

  // componentDidUpdate(prevProps) {
  // }

  shouldComponentUpdate(nextProps, nextState) {
    return nextState.authenticated !== this.state.authenticated;
  }

  redirectRoute(props) {
    const { location, history } = props;
    const { pathname } = location;

    history.push({
      pathname: "/login",
      state: { redirectUrl: pathname },
    });
  }

  render() {
    let { children } = this.props;
    const { authenticated } = this.state;

    return authenticated ? (
      <Fragment>{children}</Fragment>
    ) : (
      <Redirect to="/login" />
    );
  }
}

const mapStateToProps = (state) => ({
  setUserData: PropTypes.func.isRequired,
  user: state.user,
});

export default withRouter(
  connect(
    mapStateToProps,
    { setUserData }
  )(AuthGuard)
);
