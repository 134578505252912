import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <div>
        <footer className="main-footer">
          <p>2021 © Foodiyoo - Food Delivery</p>
          <div className="pull-right hidden-xs d-none">
            <ul>
              <li>
                <a href="#">About</a>
              </li>
              <li>
                <a href="#">Privacy</a>
              </li>
              <li>
                <a href="#">T&C</a>
              </li>
            </ul>
          </div>
        </footer>
      </div>
    );
  }
}

export default Footer;
