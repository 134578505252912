import DJHLoadable from "../../Loadable/DJHLoadable";

const BannersList = DJHLoadable({
    loader: () => import('./bannersList')
})
const bannersRoute = [{
    path: `${process.env.PUBLIC_URL}/banners`,
    exact: true,
    component: BannersList
}]

export default bannersRoute;