import DJHLoadable from "../../Loadable/DJHLoadable";

const VouchersList = DJHLoadable({
    loader: () => import('./vouchersList')
})
const voucherRoute = [{
    path: `${process.env.PUBLIC_URL}/vouchers`,
    exact: true,
    component: VouchersList
}]

export default voucherRoute;