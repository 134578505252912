import http from '../../../services/httpService'

const endpoint = "/deliveryAreas"

export const getList = (parentId, page, searchQuery) => {
    return http.get(`${endpoint}/${parentId}?page=${page}&searchQuery=${searchQuery}`);
}

export const addNewItem = (parentId, data) => {
    return http.put(`${endpoint}/${parentId}`, data);
}
export const updateItem = (id, data) => {
    return http.post(`${endpoint}/${id}`, data);
}