import DJHLoadable from "../../Loadable/DJHLoadable";

const VendorMenu = DJHLoadable({
    loader: () => import("./vendorMenu")
});

const MenuItems = DJHLoadable({
    loader: () => import("./menuItems")
});


const vendorMenuRoute = [{
        path: `${process.env.PUBLIC_URL}/vendors/menu/:id`,
        exact: true,
        component: VendorMenu
    },
    {
        path: `${process.env.PUBLIC_URL}/vendors/menu/items/:id`,
        exact: true,
        component: MenuItems
    }
];

export default vendorMenuRoute;