import DJHLoadable from "../../Loadable/DJHLoadable";
const cuisines = DJHLoadable({
    loader: () => import("./cuisines")
});

const cuisinesRoute = [{
    path: `/cuisines`,
    exact: true,
    component: cuisines
}];

export default cuisinesRoute;