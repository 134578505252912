import React, { Component } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { withRouter, Redirect } from "react-router-dom";
import { loginAction } from "./../../../redux/actions/loginActions";
import Form from "../../common/djh/forms";
import Joi from "joi-browser";
import auth from "../../../services/authService";
import { setUserData } from "../../../redux/actions/userActions";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class Login extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: { email: "", password: "" },
      style: {},
      errors: {},
    };
  }

  schema = {
    email: Joi.string()
      .email()
      .required()
      .label("Email"),
    password: Joi.string()
      .required()
      .label("Password"),
  };

  componentDidMount() {
    this.checkAuth();
  }

  checkAuth = () => {
    //Validate server login
    const user = auth.getCurrentUser();
    if (user) {
      if (user.token) {
        this.props.setUserData(user);
        auth.setDefaultHttpHeaders(user);
        this.validateRedirect();
      }
    }
  };

  doSubmit = () => {
    this.props.loginAction({ ...this.state.data }).then(() => {});
  };

  componentDidUpdate(prevProps) {
    if (this.props.login.success) {
      this.validateRedirect();
    }
  }

  validateRedirect = () => {
    const url = this.getQuery("redirect");
    if (url == undefined || url == null || url == "") {
      this.props.history.push("/");
      return;
    }

    const redirectTo = url.replace(";", "&");

    window.location = redirectTo;
    window.location.href = redirectTo;
  };

  render() {
    setTimeout(
      function() {
        this.setState({ style: { display: "none" } });
      }.bind(this),
      1000
    );

    let style = this.state.style;
    const background = require("../../../assets/images/auth-layer.png");
    return (
      <div>
        <ToastContainer />
        {/* Loader starts */}
        <div className="loader-wrapper" style={style}>
          <div className="loader bg-white">
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
            <h4>
              Have a great day at work today <span>&#x263A;</span>
            </h4>
          </div>
        </div>
        {/* Loader ends */}

        {/*page-wrapper Start*/}
        <div className="page-wrapper">
          <div className="container-fluid">
            {/*login page start*/}
            <div className="authentication-main">
              <div className="row">
                <div className="col-md-4 p-0">
                  <div
                    className="auth-innerleft"
                    style={{ backgroundImage: "url(" + background + ")" }}
                  >
                    <div className="text-center">
                      <img
                        src={require("../../../assets/images/foodiyoo-logo.png")}
                        className="logo-login"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-8 p-0">
                  <div className="auth-innerright">
                    <div className="authentication-box">
                      <h4>LOGIN</h4>
                      <h6>Enter your Email and Password For Login or Signup</h6>
                      <div className="card mt-4 p-4 mb-0">
                        <form
                          className="theme-form"
                          onSubmit={this.handleSubmit}
                        >
                          {this.renderInput("email", "Your Email")}
                          {this.renderInput("password", "Password", "password")}

                          <div className="form-group form-row mt-3">
                            <div className="col-md-3">
                              {this.renderButton(
                                "LOGIN",
                                this.props.login.loading
                              )}
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*login page end*/}
          </div>
        </div>
        {/*page-wrapper Ends*/}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  setUserData: PropTypes.func.isRequired,
  loginAction: PropTypes.func.isRequired,
  login: state.login,
  user: state.user,
});
export default withRouter(
  connect(
    mapStateToProps,
    { loginAction, setUserData }
  )(Login)
);
