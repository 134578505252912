import React, { Component } from "react";
import Table from "../../common/djh/table";
import { Link } from "react-router-dom";
class DeliveryAreaTable extends Component {
  render() {
    const { items, sortColumn, onEditAction, spinnerAreas } = this.props;

    const coulumns = [
      { path: "id", label: "Id" },
      {
        path: "name",
        label: "Name",
        content: (item) => (
          <button className="btn" onClick={() => onEditAction(item)}>
            {item.name}
          </button>
        ),
      },
    ];

    return (
      <Table
        coulumns={coulumns}
        onSort={this.props.onSort}
        sortColumn={sortColumn}
        items={items}
        spinnerArea={spinnerAreas}
      />
    );
  }
}
export default DeliveryAreaTable;
