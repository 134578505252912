import React from "react";

const Input = ({ name, lable, error, ...rest }) => {
  return (
    <div className="form-group">
      <label htmlFor={name} className="col-form-label pt-0">
        {lable}
      </label>
      <input
        id={name}
        name={name}
        {...rest}
        className="form-control form-control-lg"
      />

      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
};

export default Input;
