import React from "react";
import { usePromiseTracker } from "react-promise-tracker";
import Loader from "react-loader-spinner";
import "./spinner.css";

// import { css } from "@emotion/core";
// import ClipLoader from "react-spinners/ClipLoader";

const Spinnersdiv = ({ itemClass }) => {
  return (
    <div className="loader-box">
      <div className="loader-box">
        <div className="loader">
          <div className={itemClass}></div>
          <div className={itemClass}></div>
          <div className={itemClass}></div>
          <div className={itemClass}></div>
          <div className={itemClass}></div>
        </div>
      </div>
    </div>
  );
};

export const Spinner = ({ area }) => {
  const { promiseInProgress } = usePromiseTracker({
    area: area,
    delay: 0
  });

  return (
    promiseInProgress && (
      <div className="spinner">
        <Spinnersdiv itemClass={"line"} />
        {/* <Loader type="ThreeDots" color="#8cc63f" height={100} width={100} /> */}
      </div>
    )
  );
};
