import {
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  LOGIN_LOADING,
  USER_LOGGED_OUT
} from "../actionTyps";
import auth from "../../services/authService";
import {
  setUserData
} from "./userActions";
export const loginAction = ({
  email,
  password
}) => dispatch => {
  return new Promise((resolve, reject) => {

    dispatch({
      type: LOGIN_LOADING
    });

    //do login
    auth.login(email, password)
      .then(({
        data
      }) => {
        auth.setUser(data);
        dispatch(setUserData(data));
        dispatch({
          type: LOGIN_SUCCESS
        });

        resolve();
      })
      .catch(error => {
        let message = "";

        if (error.response && error.response.data) {
          message = error.response.data.message;
        }
        dispatch({
          type: LOGIN_ERROR,
          payload: {
            message: message
          }
        });
        reject(error);
      });

  });
};

export const logoutAction = () => dispatch => {
  dispatch({
    type: USER_LOGGED_OUT
  });
};