import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
// import { Router as BrowserRouter, Route, Switch } from "react-router";
import { ScrollContext } from "react-router-scroll-4";
import DJHLoadable from "./components/Loadable/DJHLoadable";
import "./index.scss";
import "./extraStyle.css";

// ** Import custom components **
import store from "./store";
import App from "./components/app";

// Dashboards

// import Default from "./components/dashboard/default/index.component";

import Ecommerce from "./components/dashboard/ecommerce/index.component";
// UI

import Error404 from "./components/pages/error-404";
import Register from "./components/pages/register";
import ForgetPassword from "./components/pages/forget-password";
import ResetPassword from "./components/pages/reset-password";
import Comingsoon from "./components/pages/comingsoon";
import Maintenance from "./components/pages/maintenance";

import AppContext from "./components/appContext";

import AuthGuard from "./components/auth/AuthGuard";
import Auth from "./components/auth/auth";
import routes from "./components/root-routing";
import Login from "./components/portal/auth/login";

import ProtectedRoute from "./components/auth/ProtectedRoute";

const Default = DJHLoadable({
  loader: () => import("./components/dashboard/default/index.component"),
});

class Root extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <AppContext.Provider>
        <Provider store={store}>
          <BrowserRouter basename={"/"}>
            <ScrollContext>
              <Switch>
                <Route
                  path={`${process.env.PUBLIC_URL}/pages/error-404`}
                  component={Error404}
                />

                <Route
                  path={`${process.env.PUBLIC_URL}/pages/register`}
                  component={Register}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/pages/forget-password`}
                  component={ForgetPassword}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/pages/reset-password`}
                  component={ResetPassword}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/pages/coming-soon`}
                  component={Comingsoon}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/pages/maintenance`}
                  component={Maintenance}
                />

                <Route
                  path={`${process.env.PUBLIC_URL}/login`}
                  component={Login}
                />

                <App>
                  {/*****  Foodiyoo Hub *****/}
                  <Auth>
                    <AuthGuard>
                      <ProtectedRoute
                        exact
                        path={`${process.env.PUBLIC_URL}/`}
                        component={Default}
                      />

                      {routes.map((item, i) => (
                        <ProtectedRoute
                          key={i}
                          path={item.path}
                          component={item.component}
                          exact={item.exact}
                        />
                      ))}
                    </AuthGuard>
                  </Auth>

                  {/*****  //Foodiyoo Hub *****/}

                  <Route
                    path={`${process.env.PUBLIC_URL}/dashboard/default`}
                    component={Default}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/dashboard/ecommerce`}
                    component={Ecommerce}
                  />
                </App>
              </Switch>
            </ScrollContext>
          </BrowserRouter>
        </Provider>
      </AppContext.Provider>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));
