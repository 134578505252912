import DJHLoadable from "../../Loadable/DJHLoadable";
const DeliveryAreaList = DJHLoadable({
    loader: () => import('./deliveryAreaList')
})
const deliveryAreaRoute = [{
        path: `${process.env.PUBLIC_URL}/deliveryArea`,
        exact: true,
        component: DeliveryAreaList
    },
    {
        path: `${process.env.PUBLIC_URL}/deliveryArea/:id`,
        component: DeliveryAreaList,
        exact: true
    }
]

export default deliveryAreaRoute;