import React, { Component } from "react";
import Breadcrumb from "../../common/breadcrumb.component";
import Pagination from "../../common/djh/pagination";
import SearchBox from "../../common/djh/searchBox";
import { trackPromise } from "react-promise-tracker";
import { spinnerAreas } from "./spinnerAreas";
import { withRouter } from "react-router-dom";
import { getList } from "./tableService";
import DeliveryAreaTable from "./table";

class DeliveryAreaList extends Component {
  state = {
    rowsPerPage: 10,
    page: 0,
    dataResponse: {
      data: [],
      currentPage: 0,
      pageCount: 0,
      pageSize: 0,
      rowCount: 0,
      firstRowOnPage: 0,
      lastRowOnPage: 0,
    },
    sortColumn: { path: "name", order: "asc" },
    searchQuery: "",
    spinnerAreas: spinnerAreas.main,
    modalEdit: { open: false, selectedItem: null },
    parentId: 0,
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    var parentId = 0;
    if (id) {
      parentId = id;
      this.setState({ parentId });
    }
    const nextPage = this.state.dataResponse.currentPage + 1;
    this.fetchData(parentId, nextPage);
  }

  handleSuccess = () => {
    this.fetchData(this.state.parentId, this.state.dataResponse.currentPage);
    this.handleCloseEditModal();
  };

  fetchData = (parentId, page, searchQuery = "") => {
    trackPromise(
      getList(parentId, page, searchQuery).then(({ data }) => {
        this.setState({ dataResponse: data });
      }),
      this.state.spinnerAreas
    );
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn: sortColumn });
  };

  handlePageChange = (page) => {
    this.props.history.push(`${window.location.pathname}?page=${page}`);
    this.setState({ currentPage: page });
    this.fetchData(this.state.parentId, page);
  };

  handleSearch = (searchQuery) => {
    this.setState({
      searchQuery,
      dataResponse: {
        data: [],
        currentPage: 0,
        pageCount: 0,
        pageSize: 0,
        rowCount: 0,
        firstRowOnPage: 0,
        lastRowOnPage: 0,
      },
    });

    this.fetchData(this.state.parentId, 1, searchQuery);
  };

  handleCloseEditModal = () => {
    this.setState({ modalEdit: { open: false, selectedItem: null } });
  };

  handleEditModal = (item) => {
    this.props.onSuccess(item);
    this.setState({
      modalEdit: { open: true, selectedItem: item },
    });
  };

  render() {
    const { sortColumn, spinnerAreas } = this.state;
    const {
      currentPage,
      pageSize,
      data: dataList,
      rowCount,
    } = this.state.dataResponse;

    return (
      <div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="table-responsive">
                  <DeliveryAreaTable
                    sortColumn={sortColumn}
                    onSort={this.handleSort}
                    items={dataList}
                    onEditAction={this.handleEditModal}
                    spinnerAreas={spinnerAreas}
                  />

                  <Pagination
                    itemsCount={rowCount}
                    pageSize={pageSize}
                    onPageChange={this.handlePageChange}
                    currentPage={currentPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(DeliveryAreaList);
