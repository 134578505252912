import DJHLoadable from "../../Loadable/DJHLoadable";
const OrderList = DJHLoadable({
    loader: () => import('./orderList')
})

const OrderDetails = DJHLoadable({
    loader: () => import('./orderDetails')
})

const ordersRoute = [{
        path: `${process.env.PUBLIC_URL}/orders`,
        exact: true,
        component: OrderList
    },
    {
        path: `${process.env.PUBLIC_URL}/order/:id`,
        component: OrderDetails,
        exact: true
    }, {
        path: `${process.env.PUBLIC_URL}/orders/status/:status`,
        component: OrderList,
        exact: true
    }
]

export default ordersRoute;